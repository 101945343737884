import React, { useState, useContext, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {API_HOST, fetcher} from '../../config/config';
import {AppContext} from "../../config/context";

const defaultItem = {
	email: '',
	firstname: '',
	name: '',
	full_name: '',
}

export default function MetaHeader() {
  const [currentUser, setCurrentuser] = useState(defaultItem);
	const context = useContext(AppContext);

	const saveCurrentuser = (user) => {
		setCurrentuser(user);
		context.setUser(user);
	}

  const signOff = () => {
		context.logout();
		window.location.href = '/';
	}

	useEffect(() => {
		(async () => {
      const user = await fetcher(`${API_HOST}auth/me`);
			saveCurrentuser(user);
		})();
	}, []);

  return (
    <Navbar variant="dark" bg="dark" >
      <Container >
        <Navbar.Brand href="/">SYNFONI</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example">
          {context.user?.admin &&
            <Nav>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Admin"
                menuVariant="dark"
              >
                <NavDropdown.Item href="/">Projekte</NavDropdown.Item>
                <NavDropdown.Item href="/companies">Firmen</NavDropdown.Item>
                <NavDropdown.Item href="/users">Benutzer</NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
            </Nav>
          }
          <Nav>
            <Nav.Item><Nav.Link href="mailto:support@3st.de?subject=Synfoni Support">Support</Nav.Link></Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavDropdown
              title={context.user?.full_name}
              menuVariant="dark"
            >
              <NavDropdown.Item href="/users/me/edit">Mein Account</NavDropdown.Item>
              <NavDropdown.Item onClick={signOff}>Abmelden</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
