import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {API_HOST, BIN_JWT_TOKEN } from '../../config/config';

import PublicHeader from "../Header/PublicHeader";
import Footer from "../Footer/Footer";

async function login(email, password) {
	try {
		const response = await fetch(`${API_HOST}auth/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({email, password}),
		});

		if (response.status === 401 || response.status === 500 ) {
		  const error = await response.json();
		  throw new Error( error.exception);
    }

		const token = await response.json();
		localStorage.setItem(BIN_JWT_TOKEN, token.token);
		return token;
	} catch (error) {
		console.error('Error:', error);
	}
}

function LoginLayout() {
	const [email, setEmail] = useState([]);
	const [password, setPassword] = useState([]);
	const [hasError, setHasError] = useState(false);
	const navigate = useNavigate();

	const updateEmail = (event) => {
		setEmail(event.target.value);
	}

	const updatePassword = (event) => {
		setPassword(event.target.value);
	}

	const onSubmit = () => {
		(async () => {
      try {
        if (email.length === 0 || password.length === 0) {
          setHasError(true);
          return;
        }

        if (await login(email, password)) {
          const return_to = sessionStorage.getItem('return_to');
          if (return_to) {
            sessionStorage.removeItem('return_to');
            window.location.href = return_to;
          } else {
            navigate(`/`);
          }
        } else {
          setHasError(true);
        }
      } catch (error) {
        console.error('Error:', error);
        setHasError(true);
      }
		})();
	}

	return (
		<>
      <PublicHeader/>

      <div className="container-xxl bg-white text-bg-light mt-5 py-4">
        <div className="row">
          <div className="col">
            <h2 className="mb-4">Login</h2>
          </div>
        </div>

        <div className="row">
          <div className="col">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">E-Mail</label>
                <input type="email" className={`form-control ${hasError ?  `is-invalid` : '' }`} id="email" value={email} onChange={updateEmail}/>
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Passwort</label>
                <input className={`form-control ${hasError ?  `is-invalid` : '' }`} name="password" type="password" id="password" value={password} onChange={updatePassword}/>
                {hasError &&
                  <div className="invalid-feedback">
                    Ihre Zugangsdaten scheinen nicht korrekt zu sein.
                  </div>
                }
              </div>
              <div className="py-3">
                <button type="button" className="btn btn-primary" onClick={onSubmit} >Anmelden</button>
                <a className="px-4" href="/password/forgot">Passwort zurücksetzen</a>
              </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default LoginLayout;
