export default function Notification({ message={} }) {
	const hasValidType = ['info', 'warning', 'danger', 'error', 'success'].includes(message.type);
	if ( message.message && hasValidType) {
		return <div className="container-xxl bg-white text-bg-light py-4 mb-4">
						<div className="row">
							<div className="col">
								<div className={`alert alert-${String(message.type)}`} role="alert">{message.message}</div>
							</div>
						</div>
					</div>
	}
	return null;
}
