import { createContext, useState } from 'react';
import { deleteToken } from './config';

export const AppContext = createContext(null);

const logout = () => {
	deleteToken();
};

export default function UseAppContext({children}) {
	const [refetchRules, setRefetchRules] = useState(false);
	const [user, setUser] = useState(null);
	const [filter, setFilter] = useState();

	const providerValue = {
		refetchRules,
		setRefetchRules,
		user,
		setUser,
		logout,
		filter,
		setFilter
	}

	return (
		<AppContext.Provider value={providerValue}>
				{children}
		</AppContext.Provider>
  )
}
