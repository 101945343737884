import React from 'react';

export default function ProjectTabs(props) {
  return (
    <div className="container-xxl pt-2">
      <div className="row">
        <div className="col">
          <ul className="nav nav-tabs">
            {props?.tabs?.map((tab) => {
              return <li className="nav-item" key={tab.id}>
                <a className={`nav-link  ${(tab.id == props.activeTabId) ? 'active' : '' } `} href={`/projects/${tab.project_id}/tab/${tab.id}`}>{tab.name}</a>
              </li>
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
