import React, {useEffect, useState, useContext} from 'react';
import { useParams } from 'react-router-dom';
import {API_HOST, fetcher, downloadFile} from '../../config/config';
import {AppContext} from '../../config/context';
import Loader from "../../components/Loader/Loader";

export default function EntryComments(){
	const context = useContext(AppContext);
	const {project_id, tab_id, entry_id } = useParams();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeUser, setActiveUser] = useState(0);

  const getFile = async (comment, upload) => {
    await downloadFile( `${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/comment/${comment.id}/upload/${upload.id}/download`, upload.name);
  }

	useEffect(() => {
		(async () => {
      const comments = await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/comments`);
			setComments(comments);
      setLoading(false);
		})();
	}, [project_id, tab_id, entry_id]);

	useEffect(() => {
    setActiveUser(context.user?.id);
	}, [context.user]);

  if (loading) {
    return <Loader/>
  }
	return (
		<>
      <a id="comments" tab-index="-1"></a>
      <h2>Kommentare</h2>

        { comments.map((comment, key) => {
          return <div key={key}>
            <p className={`d-flex justify-content-between mb-1 px-3 ${ (parseInt(activeUser) === parseInt(comment.user_id)) ? 'text-brand ms-5' : 'me-5'} `}>
              <small>{ comment.author }</small>
              <small>{ comment.created_at } – <a href={`#comment-${(comments.length - key)}`} id={`comment-${(comments.length - key)}`}>#{(comments.length - key)}</a></small>
            </p>
            <div className={`rounded border ${ (parseInt(activeUser) === parseInt(comment.user_id)) ? 'border-brand text-bg-white ms-5' : 'text-bg-light me-5' } px-3 py-3 mb-4`}>
              <p dangerouslySetInnerHTML={{__html: comment.body_html}} />

                {comment.uploads.length > 0 &&
                  <>
                    <hr/>
                    {comment.uploads.map((upload) => {
                      return <div className="d-block mb-1" key={upload.id}>
                        <img src="/assets/icons/file.svg" alt="File" className="me-1"/>
                        <a href="#" onClick={()=>getFile(comment, upload)}><small>{ upload.name }</small></a>
                      </div>
                    })}
                  </>
                }

              {comment.recipients.length > 0 &&
                <>
                  <hr/>
                  {comment.recipients.map((recipient) => {
                    return <div className="d-inline-block mb-1 me-3" key={recipient.id}>
                      <img src="/assets/icons/user.svg" alt="User" className="me-1" />
                      <small>{recipient.full_name}</small>
                    </div>
                  })}
                </>
              }
            </div>
          </div>
        })}
    </>
  )
}
