import { saveAs } from 'file-saver';

export const API_HOST = process.env.REACT_APP_API_HOST
export const APP_ENV = process.env.REACT_APP_ENV

export const BIN_JWT_TOKEN = 'bin_jwt_token'

export function deleteToken() {
  return localStorage.removeItem(BIN_JWT_TOKEN);
}

export function getToken() {
  return localStorage.getItem(BIN_JWT_TOKEN);
}

export async function fetcher(url, method='GET', data = null, secure = true, formData = false) {
  if (method === 'GET') {
    return getFetcher(url, secure);
  } else {
    return postFetcher(url, method, data, secure, formData);
  }
}

async function getFetcher(url, secure) {
  try {
    const headers = {
      'Content-Type': 'application/json'
    }

    if (secure) {
      headers['Authorization'] = 'Bearer ' + getToken();
    }

		const response = await fetch(url, {
			method: 'GET',
			headers: headers,
		})

		if (response.status === 200) {
			return response.json();
		} if (response.status === 401) {
      sessionStorage.setItem('return_to', window.location.href);
			window.location.href = '/login';
		} if (response.status === 500) {
		  const error = await response.json();
		  throw new Error( error.exception);
	  	}
	} catch (error) {
	  throw error;
	}
}

async function postFetcher(url, method, data, secure, formData = false) {
  try {
    let body = null;
    let headers = {};

    if (formData) {
      headers = {};
      body = data;
    } else {
      headers = { 'Content-Type': 'application/json' }
      body = JSON.stringify(data);
    }

    if (secure) {
      headers['Authorization'] = 'Bearer ' + getToken();
    }

		const response = await fetch(url, {
			method: method,
			headers: headers,
      body: body
		})

		if (response.status === 200) {
			return response.json();
		} if (response.status === 401) {
      sessionStorage.setItem('return_to', window.location.href);
			window.location.href = '/login';
		} if (response.status === 422) {
			const error = await response.json();
			throw new Error( error.error);
			//return {status: response.status, message: await response.json().then(data => data.error)};
		} if (response.status === 500) {
			const error = await response.json();
			throw new Error( error.exception);
			//return {status: response.status, message: await response.json().then(data => data.error)};
		}
	} catch (error) {
		throw error;
		//console.error(error.status);
	}
}

export async function downloadFile(url, filename, secure = true) {
  try {
    const headers = {}

    if (secure) {
      headers['Authorization'] = 'Bearer ' + getToken();
    }

		const response = await fetch(url, { headers: headers })
    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);

	} catch (error) {
    throw new Error(error);
	}
}
