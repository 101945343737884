
import React, {useContext} from 'react';
import {AppContext} from "../../config/context";

export default function AdminTabs(props) {
	const context = useContext(AppContext);
 
  return (
    <div className="container-xxl pt-2">
      <div className="row">
        <div className="col">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className={`nav-link  ${(props.tab === 'projects') ? 'active' : '' } `} aria-current="page" href="/">Projekte</a>
            </li>
            {!!context.user?.admin &&
              <>
                <li className="nav-item">
                  <a className={`nav-link  ${(props.tab === 'companies') ? 'active' : '' } `} href="/companies">Firmen</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link  ${(props.tab === 'users') ? 'active' : '' } `} href="/users">Benutzer</a>
                </li>
              </>
            }
            {!context.user?.admin &&
              <>
                <li className="nav-item">
                  <a className={`nav-link  ${(props.tab === 'users') ? 'active' : '' } `} href="/users/me/edit">Ihr Account</a>
                </li>
              </>
            }
          </ul>
        </div>
      </div>
    </div>
  );
}
