import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-xxl p-4">
        <div className="row">
          <div className="col">
            <a className="pr-2" href="https://www.3st.de/impressum" target="_blank">Impressum</a>
            <a className="px-2" href="https://www.3st.de/datenschutz" target="_blank">Datenschutz</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
