import React, {useEffect, useState, useContext} from 'react';
import {API_HOST, fetcher} from '../../config/config';
import {AppContext} from "../../config/context";
import Loader from "../../components/Loader/Loader";

export default function Entries(props){
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(props.mode);
  const [searchTerm, setSearchTerm] = useState('');

	const context = useContext(AppContext);

  const searchEntries = () => {
    const filteredEntries = entries.filter(entry => entry.headline.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredEntries(filteredEntries);
  }

	useEffect(() => {
    searchEntries();
	}, [searchTerm]);


	useEffect(() => {
		(async () => {
      const entries = await fetcher(`${API_HOST}projects/${props.project.id}/tabs/${props.tab.id}/entries?mode=${mode}`);
			setEntries(entries);
			setFilteredEntries(entries);
      setLoading(false);
		})();
	}, [props.tab.id, props.project.id, mode]);

  if (loading) {
    return <Loader/>
  }

	return (
		<>
      <div className="container-xxl bg-white text-bg-light py-4">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-auto">
                      <div className="mb-4">
                        <a href={`/projects/${props.project.id}/tab/${props.tab.id}/entry/new`} className="btn btn-primary">+ Inhalt hinzufügen</a>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="mb-4">
                        <input type="seach" className="form-control" id="seach" value={searchTerm} placeholder="Suche" onChange={(e) => setSearchTerm(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <ul className="nav nav-underline mb-3 justify-content-end">
                    <li className="nav-item">
                      <a className={`nav-link ${(mode === 'published') ? 'active' : '' }`} aria-current="page" href="#" onClick={()=>setMode("published")}><img src="/assets/icons/check.svg" alt="Check"/> Aktuell</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${(mode === 'archived') ? 'active' : '' }`} href="#" onClick={()=>setMode("archived")}><img src="/assets/icons/inbox.svg" alt="Archiv"/> Archiv</a>
                    </li>

                    {context.user?.admin &&
                      <li className="nav-item">
                        <a className={`nav-link ${(mode === 'deleted') ? 'active' : '' }`} href="#" onClick={()=>setMode("deleted")}><img src="/assets/icons/trash-2.svg" alt="Trash"/> Papierkorb</a>
                      </li>
                    }

                  </ul>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Titel</th>
                      <th>Benutzer</th>
                      <th>Firma</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEntries.map((entry) => {
                      return <tr key={entry.id}>
                        <td>{entry.created_at}</td>
                        <td><a href={`/projects/${props.project.id}/tab/${props.tab.id}/entry/${entry.id}`}>{entry.headline}</a></td>
                        <td>{entry.author}</td>
                        <td>{entry.firma}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
