import React, {useState, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {API_HOST} from '../../config/config';

import PublicHeader from "../Header/PublicHeader";
import Footer from "../Footer/Footer";
import Notification from '../Notification/Notification';
import Loader from "../Loader/Loader";

import {
  checkPasswordPolicy,
} from '../../services/UserService';


async function sendResetRequest(new_password, token) {
	try {
		const response = await fetch(`${API_HOST}password/reset`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({new_password, token}),
		});

		if (response.status === 401 || response.status === 500 ) {
		  const error = await response.json();
		  throw new Error( error.exception);
    }
    return true;
	} catch (error) {
		console.error('Error:', error);
	}
}

function PasswordReset() {
  const [searchParams, ] = useSearchParams();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
	const [new_password, setNewPassword] = useState([]);
	const [new_password_confirmation, setNewPasswordConfirmation] = useState([]);
  const [passwordMessage, setPasswordMessage] = useState({message: '', type: ''});
	const [hasError, setHasError] = useState(false);
	const navigate = useNavigate();

	const updatePassword = (event) => {
		setNewPassword(event.target.value);
	}

	const updatePasswordConfirmation = (event) => {
		setNewPasswordConfirmation(event.target.value);
	}

  const validate = () => {
    if (new_password_confirmation.length === 0 || new_password.length === 0) {
      setPasswordMessage({message: 'Bitte geben Sie das Passwort und Passwortbestätigung ein', type: 'danger'});
      return;
    }

    if (new_password_confirmation !== new_password) {
      setPasswordMessage({message: 'Das Passwort und die Passwortbestätigung stimmen nicht überein', type: 'danger'});
      return;
    }

    if (!checkPasswordPolicy(new_password)) {
      setPasswordMessage({message: 'Das eingegene Passwort entspricht nicht den Anforderungen (Mindestens: Länge 10 Zeichen, ein Grossbuchstabe, eine Zahl)', type: 'danger'});
      return;
    }
    return true;
  }

	const onSubmit = () => {
		(async () => {
      try {
        if (validate() !== true) {
          setHasError(true);
          return;
        }

        if (await sendResetRequest(new_password, token)) {
          navigate(`/`);
        } else {
          setHasError(true);
        }
      } catch (error) {
        console.error('Error:', error);
        setHasError(true);
      }
		})();
	}

	useEffect(() => {
		(async () => {
      if (searchParams.has("token")) {
        setToken(searchParams.get("token"));
        setLoading(false);
      }
		})();
	}, []);

  if (loading) {
    return <Loader/>
  }

	return (
		<>
      <PublicHeader/>

      {hasError &&
        <Notification message={passwordMessage}/>
      }

      <div className="container-xxl bg-white text-bg-light mt-5 py-4">
        <div className="row">
          <div className="col">
            <h2 className="mb-4">Passwort zurücksetzen</h2>
          </div>
        </div>

        <div className="row">
          <div className="col">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Passwort</label>
                <input className={`form-control ${hasError ?  `is-invalid` : '' }`} name="new_password" type="password" id="password" value={new_password} onChange={updatePassword}/>
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Passwort bestätigen</label>
                <input className={`form-control ${hasError ?  `is-invalid` : '' }`} name="new_password_confirmation" type="password" id="password" value={new_password_confirmation} onChange={updatePasswordConfirmation}/>
              </div>
              <div className="py-3">
                <button type="button" className="btn btn-primary" onClick={onSubmit} >Passwort speichern</button>
                <a className="px-4" href="/">Abbrechen</a>
              </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default PasswordReset;
