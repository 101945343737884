import React from 'react';
import {
  EditorProvider,
  Editor,
  Toolbar,
  Separator,
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
  BtnStrikeThrough
} from "react-simple-wysiwyg";

export default function SimpleEditor(props){
	return (
    <EditorProvider>
      <Editor value={props.value} onChange={props.onChange}>
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikeThrough/>
          <Separator/>
          <BtnNumberedList/>
          <BtnBulletList/>
        </Toolbar>
      </Editor>
    </EditorProvider>
  )
}
