import React, {useEffect, useState, useContext} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import {API_HOST, fetcher} from '../../config/config';
import {AppContext} from "../../config/context";
import Loader from "../../components/Loader/Loader";


export default function Projects(){
  const [projects, setProjects] = useState({});
  const [changes, setChanges] = useState({});
	const context = useContext(AppContext);
  const [showUpdates, setShowUpdates] = useState(false);
  const [selectedProject, setSelectedProject] = useState(0);
  const [mode, setMode] = useState("active");

  const handleClose = () => {
    setSelectedProject(0);
    setShowUpdates(false);
  }

  const handleShowUpdates = (project_id) => {
    setSelectedProject(project_id);
  }

  const checkUpdatesSinceLastLogin = (updatedAt , lastLoginDate) => {
    const uploadDate = new Date(updatedAt);
    const loginDate = new Date(lastLoginDate);
    return (uploadDate > loginDate);
  }

	useEffect(() => {
    if (!selectedProject) {
      return;
    }

		(async () => {
      const changes = await fetcher(`${API_HOST}projects/${selectedProject}/latest-changes`);
      setChanges(changes);
      setShowUpdates(true);
		})();
	}, [(selectedProject)]);

	useEffect(() => {
		(async () => {
      const projects = await fetcher(`${API_HOST}projects?mode=${mode}`);
			setProjects(projects);
		})();
	}, [mode]);

  const renderChanges = (tab, entries) => {
    if (entries.length === 0) {
      return;
    }

    return <div className="py-3 border-top" key={Math.random()}>
      <span className="d-flex justify-content-between">
        <small>{tab}</small>
        <span className="badge text-bg-success">Neu</span>
      </span>
      { entries.map((entry) => {
        return <div key={entry.id}>
          <strong className="d-block py-1"><a href={`/projects/${entry.project_id}/tab/${entry.tab_id}/entry/${entry.id}`}>{entry.headline}</a></strong>
          <span className="d-flex justify-content-between">
            <small>{entry.author}</small>
            <small>{entry.created_at}</small>
          </span>
          </div>
      })}
     </div>
  }

  if (!projects || projects.length === 0) {
    <Loader />
  }

	return (
      <div className="container-xxl bg-white text-bg-light py-4">
        <div className="row">
          <div className="col">

            <div className="row">
              <div className="col-12 col-sm-4">
                {context.user?.admin &&
                  <div className="mb-4">
                    <a href={`/projects/new`} className="btn btn-primary">+ Projekt hinzufügen</a>
                  </div>
                }
              </div>
              <div className="col-12 col-sm-8">
                <ul className="nav nav-underline mb-3 justify-content-end">
                  <li className="nav-item">
                    <a className={`nav-link ${(mode === 'active') ? 'active' : '' }`} aria-current="page" href="#" onClick={()=>setMode("active")}><img src="/assets/icons/check.svg" alt="Check"/> Aktuell</a>
                  </li>
                  {context.user?.admin &&
                    <li className="nav-item">
                      <a className={`nav-link ${(mode === 'archived') ? 'active' : '' }`} href="#" onClick={()=>setMode("archived")}><img src="/assets/icons/inbox.svg" alt="Archiv"/> Archiv</a>
                    </li>
                  }
                </ul>
              </div>
            </div>

            { Object.entries(projects).map(([client, pp]) => {
              return <div className="table-responsive mb-3" key={client}>
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>{client}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { pp.map((project) => {
                      return <tr key={project.id}>
                        <td><a href={`/projects/${project.id}`}>{project.name}</a></td>
                        <td className="text-end">
                          {checkUpdatesSinceLastLogin(project.updated_at, context.user.last_login) &&
                            <button className="btn btn-warning btn-sm" onClick={()=>handleShowUpdates(project.id)}>Updates</button>
                          }
                          {context.user?.admin &&
                            <a href={`/projects/${project.id}/edit`} className="btn btn-light btn-sm">Bearbeiten</a>
                          }
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
          })}
          </div>
        </div>

        <Offcanvas show={showUpdates} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Letzte Änderungen</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            { Object.entries(changes).map(([tab, entries]) => {
              return renderChanges(tab, entries);
            })}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
	)
}
