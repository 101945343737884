import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import SimpleEditor from "../../components/Editor/SimpleEditor";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import Notification from '../../components/Notification/Notification';
import EntryNotifications from "./EntryNotifications";

import {API_HOST, fetcher} from '../../config/config';

const defaultProps = {
  body: "",
  user_id: 0,
  entry_id: 0,
  recipients_list: [],
};

export default function EntryForm(props){
	const {project_id, tab_id, entry_id} = useParams();
  const [disabledSave, setDisabledSave] = useState(true);
  const [comment, setComment] = useState(defaultProps);
	const [response, setResponse] = useState({message: '', type: ''});
  const [uploads, setUploads] = useState([]);

  const onBodyChange = (e) => {
    comment.body = e.target.value;
    setComment({...comment});
  }

  const onRecipientsChange = (recipients) => {
    comment.recipients_list = recipients;
    setComment({...comment});
  }

  const onDrop = (files) => {
    if (files.length !== 0) {
      setUploads([...files]);
    }
    if (files.length === 0) {
      setUploads([]);
    }
  }

  const validate = () => {
    if (comment.body.length === 0) {
      setResponse({type: 'danger', message: "Bitte geben Sie Ihren Kommentar ein"});
      return false;
    }
    return true;
  }

  const onSave = async () => {
		(async () => {

      props.onLoading(true);

      if (!validate()) {
        return;
      }

      try {
        const newComment = await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/comments`, 'POST', comment);

        if (!!uploads?.length) {
          const headers = { 'Content-Type': 'multipart/form-data' };
          const data = new FormData();
          uploads.forEach(upload =>
            data.append("uploads[]", upload)
          );

          await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/comments/${newComment.id}/upload`, 'POST', data, true, headers);
        }
        props.onLoading(false);

        setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
        window.location.reload();

			} catch (error) {
				setResponse({type: 'danger', message: error?.message});
			}
		})();
  }

	return (
    <section className="mb-5 border-bottom pb-4">
      <Notification message={response}/>

      <p><strong><img src="/assets/icons/edit.svg" alt="File"/> Neuer Kommentar</strong></p>
        <div className="mb-3">
          <label htmlFor="comment" className="form-label visually-hidden">Kommentartext</label>
          <SimpleEditor value={comment.body} onChange={onBodyChange} className="form-control"/>
        </div>

        <DragAndDrop onDrop={onDrop}/>

        <EntryNotifications tab={props.tab} onUserSelection={(users)=> onRecipientsChange(users)} onStatusChange={(status) => setDisabledSave(status)}/>

        <div className="mb-3">
          <button type="submit" className="btn btn-primary" disabled={disabledSave} onClick={onSave}>Kommentar absenden</button>
        </div>
    </section>
  )
}
