import React, {useEffect, useState, useContext} from 'react';
import {Routes, Route} from "react-router-dom";
import { useParams } from 'react-router-dom';
import MetaHeader from "../../components/Header/MetaHeader";
import Footer from "../../components/Footer/Footer";
import ProjectTabs from "./ProjectTabs";
import Entries from "../Plugins/Entries";
import Entry from "../Plugins/Entry";
import EntryNew from "../Plugins/EntryNew";
import Contact from "../Plugins/Contact";

import {API_HOST, fetcher} from '../../config/config';
import {AppContext} from "../../config/context";
import Loader from "../../components/Loader/Loader";

export default function Tabs(){
	const {project_id, tab_id } = useParams();
  const [project, setProject] = useState();
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState(true);

  const context = useContext(AppContext);

	useEffect(() => {
		(async () => {
      const project = await fetcher(`${API_HOST}projects/${project_id}`);
			setProject(project);

      if (tab_id) {
        const tab = project.tabs.find((tab) => parseInt(tab.id) === parseInt(tab_id));
        if (tab) {
          setActiveTab(tab);
        }
      } else {
        setActiveTab(project.tabs[0]);
      }

      setLoading(false);
		})();
	}, [project_id, tab_id]);

  if (loading) {
    return <Loader/>
  }

	return (
		<>
      <header className="mb-4">

        <MetaHeader/>

        <div className="bg-brand text-white py-4">
          <div className="container-xxl">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-between">
                  <h1 className="title">{project?.name}</h1>
                  {!!context?.user?.admin &&
                    <a className="btn btn-outline-light" href={`/projects/${project?.id}/edit`}> Projekt bearbeiten</a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <ProjectTabs tabs={project?.tabs} activeTabId={activeTab?.id}/>

      <Routes>
        {activeTab?.plugin_id === 2 &&
          <>
            <Route path="entry/new" element={<EntryNew project={project} tab={activeTab}/>}/>
            <Route path="entry/:entry_id" element={<Entry project={project} tab={activeTab}/>}/>

            <Route path="/archiv" element={<Entries project={project} tab={activeTab} mode={"archive"}/>}/>
            <Route path="/trash" element={<Entries project={project} tab={activeTab} mode={"trash"}/>}/>

            <Route index element={<Entries project={project} tab={activeTab} mode={"published"}/>}/>
          </>
        }

        {activeTab?.plugin_id === 3 &&
          <>
            <Route index element={<Contact project={project} />}/>
          </>
        }

		  </Routes>
      <Footer/>
    </>
  )
}
