import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import {API_HOST, fetcher} from '../../config/config';
import Notification from '../../components/Notification/Notification';

const defaultProject = {
  name: '',
  customer_id: 0,
}

export default function ProjectNew(){
  const [project, setProject] = useState(defaultProject);
  const [firmas, setFirmas] = useState([]);
	const [response, setResponse] = useState({message: '', type: ''});

	const navigate = useNavigate();

  const firmasOptions = () => {
    const options = [];
    options.push(<option value={0} key={0}>– Auswählen –</option>);

    firmas.forEach((firma) => {
        options.push(<option value={firma.id} key={firma.id}>{firma?.name}</option>);
    })
    return options;
  }

  const onNameChange = (e) => {
    project.name = e.target.value;
    setProject({...project});
  }

  const onCustomerChange = (e) => {
    project.customer_id = parseInt(e.target.value);
    setProject({...project});
  }

	const onSave = () => {
		(async () => {
			try {
				const newProject = await fetcher(`${API_HOST}projects`, 'POST', project);
				setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
        navigate(`/projects/${newProject.id}/edit/#new`);
			} catch (error) {
				setResponse({type: 'danger', message: error?.message});
			}
		})();
	}

	useEffect(() => {
		(async () => {
      const firmas = await fetcher(`${API_HOST}firmas`);
			setFirmas(firmas);
		})();
	}, []);

	return (
		<>
			<Notification message={response}/>

      <div className="container-xxl pt-2">
        <div className="row">
          <div className="col">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Projekt anlegen</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-xxl bg-white text-bg-light py-4 mb-4">
        <div className="row">
          <div className="col">
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Projektname</label>
                  <input type="text" className="form-control" id="name" name="name" value={project?.name} onChange={(e)=> onNameChange(e) }/>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="client" className="form-label">Kunde</label>
                  <select className="form-select" id="client" value={project?.customer_id} onChange={(e)=> onCustomerChange(e)}>
                    { firmasOptions(project?.customer_id) }
                  </select>
                </div>
              </div>
            </div>

            <div className="py-3">
              <button type="submit" name="submit" className="btn btn-primary" onClick={onSave}>Speichern</button>
              <a className="px-4" href="/">Abbrechen</a>
            </div>
        </div>
      </div>
    </div>
   </>
  )
}
