import React from 'react';

import MainHeader from "../Header/MainHeader";
import AdminTabs from "../../features/Tabs/AdminTabs";
import Footer from "../Footer/Footer";

function MainLayout(props) {
	return (
		<>
      <MainHeader/>
      <AdminTabs tab={props.tab}/>
      {props.children}
      <Footer/>
    </>
  )
}

export default MainLayout;
