import React, {useEffect, useState} from 'react';

import {API_HOST, fetcher} from '../../config/config';
import Loader from "../../components/Loader/Loader";

export default function Contact(props){
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const renderFirma = (firmaName) => {
    const firma = props.project.firmas.find((f) => f.name === firmaName)
    if (!firma || !firma.address) {
      return null;
    }

    return (
      <div className="rounded border text-bg-light px-3 py-3 mb-4">
        <div dangerouslySetInnerHTML={{__html: firma.address}} />
        <p>
          <a href={`mailto:${firma.email}`}>{firma.email}</a><br/>
          {firma.phone}
        </p>
      </div>
    )
  }

	useEffect(() => {
		(async () => {
      const users = await fetcher(`${API_HOST}projects/${props.project.id}/active_users`);
      setUsers(users);
      setLoading(false);
		})();
	}, [props]);

  if (loading) {
    return <Loader/>
  }

	return (
    <div className="container-xxl bg-white text-bg-light py-4">
      <div className="row">
        <div className="col pt-5">

          { Object.entries(users).map(([client, firma_users]) => {
            return <div className="row mb-4" key={client}>
              <div className="col-12 col-md-8">

                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th colSpan="3">
                          <h4>{client}</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {firma_users.map((user) => {
                        return <tr key={user.id}>
                          <td>{user.full_name}</td>
                          <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                          <td>{user?.phone}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-md-4">
                {renderFirma(client)}
              </div>
            </div>
          })}

        </div>
      </div>
    </div>
  )
}
