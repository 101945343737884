import React, {useEffect, useState} from 'react';

import {API_HOST, fetcher} from '../../config/config';

export default function Users(props){
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSeletedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("active");
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const firmasOptions = () => {
    const options = [];
    options.push(<option value={0} key={0}>– Auswählen –</option>);

    companies.forEach((firma) => {
      options.push(<option value={firma.id} key={firma.id}>{firma?.name}</option>);
    })
    return options;
  }

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    setSelectedCompany(0);

    const result = users.filter((user) => {
      return user?.name.toLowerCase().includes(e.target.value.toLowerCase()) || user?.firstname.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setSeletedUsers(result);
  }

  const onSelectCompany = (company_id) => {
    setSelectedCompany(company_id);
    const result = users.filter((user) => {
      return user.firma_id === company_id;
    });
    setSeletedUsers(result);
  }

	useEffect(() => {
		(async () => {
      setLoading(true);
      const companies = await fetcher(`${API_HOST}firmas/?mode=active`);
			setCompanies(companies);
			const users = await fetcher(`${API_HOST}users?mode=${mode}`);
      setUsers(users);
      setSeletedUsers(users);
      setLoading(false);
		})();
	}, [props, mode]);

  if (loading) {
    return (
      <div className="container-xxl bg-white text-bg-light py-4">
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center">
                <span className="spinner-border" role="status"></span>
                <span className="ps-2">Daten werden geladen …</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

	return (
      <div className="container-xxl bg-white text-bg-light py-4">
        <div className="row">
          <div className="col">

            <div className="row">
              <div className="col-12 col-sm-4">
                <div className="mb-4">
                  <a href={`/users/new`} className="btn btn-primary">+ Benutzer hinzufügen</a>
                </div>
              </div>

              <div className="col-12 col-sm-8">
                <ul className="nav nav-underline mb-3 justify-content-end">
                  <li className="nav-item">
                    <a className={`nav-link ${(mode === 'active') ? 'active' : '' }`} aria-current="page" href="#" onClick={()=>setMode("active")}><img src="/assets/icons/check.svg" alt="Check"/> Aktuell</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${(mode === 'archived') ? 'active' : '' }`} href="#" onClick={()=>setMode("archived")}><img src="/assets/icons/inbox.svg" alt="Archiv"/> Archiv</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="mb-4">
                  <label htmlFor="search" className="form-label">Suche</label>
                  <input type="text" className="form-control" id="search" name="search" value={searchTerm}  onChange={(e)=> onSearch(e) }/>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-4">
                  <label htmlFor="client" className="form-label">Firma</label>
                  <select className="form-select" id="client" value={selectedCompany} onChange={(e)=> onSelectCompany(parseInt(e.target.value))}>
                    { firmasOptions() }
                  </select>
                </div>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Vorname</th>
                    <th>Firma</th>
                    <th>Admin?</th>
                    <th>Letzter Login</th>
                    <th>Erstellt am</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { selectedUsers.map((user) => {
                    return <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.firstname}</td>
                      <td>{user.firma}</td>
                      <td>{(user.admin ? "ja" : "nein")}</td>
                      <td><span className="text-nowrap">{user.last_login_at_formated}</span></td>
                      <td><span className="text-nowrap">{user.created_at}</span></td>
                      <td className="text-end">
                        <a href={`/users/${user.id}/edit`} className="btn btn-light btn-sm">Bearbeiten</a>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
	)
}
