import React from 'react';
import MetaHeader from './MetaHeader';
import { useParams } from 'react-router-dom';

export default function MainHeader() {
  const {project_id} = useParams();

  return (
    <header className="mb-4">

      <MetaHeader/>

      <div className="bg-brand text-white py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-between">
                <h1 className="title">
                  3st Projektportal
                </h1>
                {project_id &&
                  <a className="btn btn-outline-light" href={`/projects/${project_id}`}> Zum Projekt </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
