import React, {useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import SimpleEditor from "../../components/Editor/SimpleEditor";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import Notification from '../../components/Notification/Notification';
import EntryNotifications from "./EntryNotifications";
import Loader from "../../components/Loader/Loader";

import {API_HOST, fetcher} from '../../config/config';

const defaultProps = {
  headline: "",
  body: "",
  tab_id: 0,
  project_id: 0,
  user_id: 0,
  recipients_list: [],
};

export default function EntryNew(props){
	const {project_id, tab_id} = useParams();
  const [entry, setEntry] = useState(defaultProps);
	const [response, setResponse] = useState({message: '', type: ''});
  const [loading, setLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [uploads, setUploads] = useState([]);

	const navigate = useNavigate();

  const onChange = (e) => {
    setEntry({...entry, [e.target.name]: e.target.value});
  }

  const onBodyChange = (e) => {
    entry.body = e.target.value;
    setEntry({...entry});
  }

  const onRecipientsChange = (recipients) => {
    entry.recipients_list = recipients;
    setEntry({...entry});
  }

  const onDrop = (files) => {
    if (files.length !== 0) {
      setUploads([...files]);
    }
  }

  const validate = () => {
    if (entry.headline.length === 0) {
      setResponse({type: 'danger', message: "Bitte geben Sie einen Titel ein"});
      return false;
    }

    if (entry.body.length === 0) {
      setResponse({type: 'danger', message: "Bitte geben Sie eine Beschreibung ein"});
      return false;
    }
    return true;
  }

  const onSave = async () => {
		(async () => {
      setLoading(true);
      if (!validate()) {
        return;
      }

      try {
        const newEntry = await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries`, 'POST', entry);

        if (!!uploads?.length) {
          const headers = { 'Content-Type': 'multipart/form-data' };
          const data = new FormData();
          uploads.forEach(upload =>
            data.append("uploads[]", upload)
          );

          await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${newEntry.id}/upload`, 'POST', data, true, headers);
        }
        setLoading(true);

        setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
        navigate(`/projects/${project_id}/tab/${tab_id}/entry/${newEntry.id}/#new`);

			} catch (error) {
				setResponse({type: 'danger', message: error?.message});
			}
		})();
  }

  if (loading) {
    return <Loader/>
  }

	return (
		<>
      <div className="container-xxl bg-white text-bg-light py-4">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-7 col-xl-8">
          </div>
          <div className="col-12 col-md-6 col-lg-5 col-xl-4">
            <ul className="nav nav-underline mb-3 justify-content-end">
              <li className="nav-item">
                <a className="nav-link" href={`/projects/${props.project.id}/tab/${tab_id}/`}><img src="/assets/icons/arrow-left.svg" alt="Back to Overview"/> Zur Übersicht</a>
              </li>
            </ul>
          </div>
        </div>

        <Notification message={response}/>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sticky-top">
              <section className="mb-5 pb-4">
                <p><strong>Neuer Beitrag</strong></p>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">Titel</label>
                  <input type="text" required={true} className="form-control" id="title" name="headline" value={entry.headline} onChange={onChange}/>
                </div>
                <div className="mb-3">
                  <label htmlFor="comment" className="form-label">Beschreibung</label>
                  <SimpleEditor value={entry.body} required={true} onChange={onBodyChange} className="form-control"/>
                </div>

                <DragAndDrop onDrop={onDrop}/>
              </section>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="py-3 mb-3">

              <EntryNotifications tab={props.tab} onUserSelection={(users) => onRecipientsChange(users)} onStatusChange={(status) => setDisabledSave(status)}/>

              <div className="mb-3">
                <button className="btn btn-primary" disabled={disabledSave} onClick={onSave}>Speichern</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
