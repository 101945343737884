
import React, {useEffect, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';

import SimpleEditor from "../../components/Editor/SimpleEditor";
import Notification from '../../components/Notification/Notification';
import Loader from "../../components/Loader/Loader";

import {API_HOST, fetcher} from '../../config/config';

const defaultProps = {
  name: "",
  alias: "",
  address: "",
};

export default function Company(){
	const {company_id} = useParams();
  const [company, setCompany] = useState(defaultProps);
	const [response, setResponse] = useState({message: '', type: ''});
  const [loading, setLoading] = useState(true);

  const { hash } = useLocation();

  const onArchive = () => {
    company.active = !company.active;
    setCompany({...company});
  }

  const onChange = (e) => {
    setCompany({...company, [e.target.name]: e.target.value});
  }

  const onBodyChange = (e) => {
    company.address = e.target.value;
    setCompany({...company});
  }

  const validate = () => {
    if (company.name.length === 0) {
      setResponse({type: 'danger', message: "Bitte geben Sie den Firmennamen ein"});
      return false;
    }
    return true;
  }
  const onSave = async () => {
		(async () => {
      try {

        if (!validate()) {
          return;
        }

        await fetcher(`${API_HOST}firmas/${company_id}/`, 'PUT', company);
        setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
			} catch (error) {
				setResponse({type: 'danger', message: error?.message});
			}
		})();
  }

	useEffect(() => {
		(async () => {
      const company = await fetcher(`${API_HOST}firmas/${company_id}`);
			setCompany(company);
      setLoading(false);

      if (hash === "#new") {
        setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
      }

		})();
	}, [company_id]);

  if (loading) {
    return <Loader/>
  }

	return (
		<>
			<Notification message={response}/>

      <div className="container-xxl bg-white text-bg-light py-4 mb-4">
        <div className="row">
          <div className="col">

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Firmenname</label>
                    <input type="text" className="form-control" id="name" name="name" value={company?.name}  onChange={(e)=> onChange(e) }/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Adresse</label>
                    <SimpleEditor value={company?.address} onChange={onBodyChange} className="form-control"/>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="alias" className="form-label">Kurzname</label>
                    <input type="text" className="form-control" id="alias" name="alias" value={company?.alias}  onChange={(e)=> onChange(e) }/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="client" className="form-label">Telefon</label>
                    <input type="text" className="form-control" id="name" name="phone" value={company?.phone}  onChange={(e)=> onChange(e) }/>
                  </div>
                  <div className="mb-3">
                    <div className="form-check d-inline-block me-3">
                      <input type="checkbox" className="form-check-input" id="archived" checked={!company.active} onChange={()=> onArchive() }/>
                      <label className="form-check-label" htmlFor="archived">Firma ist archiviert</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
              </div>

                <div className="py-3">
                  <button type="submit" name="submit" className="btn btn-primary" onClick={onSave}>Speichern</button>
                  <a className="px-4" href="/companies">Abbrechen</a>
                </div>
            </div>
          </div>
        </div>
    </>
  )
}
