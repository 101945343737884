import React, {useEffect, useState, useContext} from 'react';
import { Modal } from "react-bootstrap";
import { useParams, useLocation } from 'react-router-dom';

import Notification from '../../components/Notification/Notification';
import EntryForm from "./EntryForm";
import EntryComments from "./EntryComments";
import Loader from "../../components/Loader/Loader";

import {API_HOST, fetcher, downloadFile} from '../../config/config';
import {AppContext} from "../../config/context";

export default function Entry(props){
  let firstRun = false;

  const {hash} = useLocation();
  if (hash === '#new') {
    firstRun = true;
  }

	const {project_id, tab_id, entry_id } = useParams();
  const [entry, setEntry] = useState({});
  const [loading, setLoading] = useState(true);
	const [response, setResponse] = useState({message: '', type: ''});
  const [showDonwnloadsModal, setShowDownloadsModal] = useState(false);
  const [selectedRecipient, setSelectedRecepient] = useState();
  const [selectedRecipientDownloads, setSelectedRecepientDownloads] = useState();

	const context = useContext(AppContext);

  const handleCloseDownloadModal = () => setShowDownloadsModal(false);

  const handleShowDownloadModal = (user) => {
    if (user) {
      setSelectedRecepient(user);
      fetchDownloadsForUser(user);
      setShowDownloadsModal(true);
    }
  }

  const onLoading = (state) => {
    setLoading(state);
  }

  const getFile = async (upload) => {
    await downloadFile( `${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/upload/${upload.id}`, upload.name);
  }

  const archiveEntry = async () => {
    await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/archive`, 'PUT');
    window.location.reload();
  }

  const deleteEntry = async () => {
    await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/delete`, 'PUT');
    window.location.reload();
  }

  const undoEntry = async () => {
    await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/undo`, 'PUT');
    window.location.reload();
  }

  const fetchDownloadsForUser = async (user) => {
    const downloads = await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}/downloads/${user.id}`);
    setSelectedRecepientDownloads(downloads);
  }

  const renderRecipientDownloads = (upload ) => {
    if (!selectedRecipientDownloads) {
      return null
    }

    const download = selectedRecipientDownloads.find((download) => parseInt(download.upload_id) === parseInt(upload.id));

    if (download) {
      return <div className="d-flex justify-content-between mb-1" key={upload.id}>
        <small className='text-success fw-medium'>
          <span className='text-success'>
            <img src="/assets/icons/check.svg" alt="Check"/>&nbsp;
          </span>
          {upload.name}
        </small>
        <small className="text-secondary">
          {download.updated_at}
        </small>
      </div>
    } else {
      return <div className="d-flex justify-content-between mb-1">
              <small className="text-secondary">
                {upload.name}
              </small>
            </div>
    }
  }

	useEffect(() => {
		(async () => {
      const entry = await fetcher(`${API_HOST}projects/${project_id}/tabs/${tab_id}/entries/${entry_id}`);
			setEntry(entry);
      setLoading(false);
		})();
	}, [project_id, tab_id, entry_id]);

	useEffect(() => {
    if (firstRun) {
      setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
      window.history.replaceState("", document.title, window.location.pathname);
    }
	}, [firstRun]);

  if (loading) {
    return <Loader/>
  }

	return (
		<>
      <Notification message={response}/>

      <div className="container-xxl bg-white text-bg-light py-4">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
              <div className="mb-4">
                <h2>{entry.headline}</h2>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
              <ul className="nav nav-underline mb-3 justify-content-end">
                <li className="nav-item">
                  <a className="nav-link" href={`/projects/${props.project.id}/tab/${tab_id}/`}><img src="/assets/icons/arrow-left.svg" alt="Projects"/> Zur Übersicht</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#comments"><img src="/assets/icons/arrow-down.svg" alt="Projects"/> Kommentare</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="sticky-top">
                <div className="py-3 mb-3 border-bottom">
                  <p className="d-flex justify-content-between"><small>{entry.author}</small><small>{entry.created_at}</small></p>
                  <p dangerouslySetInnerHTML={{__html: entry.body_html}} />
                </div>

                {!!entry.recipients?.length &&
                  <div className="mb-3 pb-3 border-bottom">
                    {entry.recipients.map((recipient) => {
                      return <div className={`d-block mb-1 ${(recipient.downloads.length !== 0) ? 'text-success' : ''}`} key={recipient.id}>
                        {recipient.downloads.length !== 0 &&
                          <img src="/assets/icons/check.svg" alt="Check"/>
                        }

                        {recipient.downloads.length === 0 &&
                          <img src="/assets/icons/user.svg" alt="User"/>
                        }

                        <small className="ps-1">
                          {recipient.downloads.length !== 0 &&
                            <button className="btn-reset" onClick={()=>handleShowDownloadModal(recipient?.user)} >
                              {recipient?.user?.full_name}
                            </button>
                          }
                          {recipient.downloads.length === 0 &&
                            recipient?.user?.full_name
                          }
                        </small>
                      </div>
                    })}
                  </div>
                }

                {!!entry.uploads?.length &&
                  <div className="mb-3 pb-3 border-bottom">
                    {entry.uploads.map((upload) => {
                      return <div className="d-block mb-1" key={upload.id}>
                        <img src="/assets/icons/file.svg" alt="File"/>
                        <button className="btn-reset ms-1" onClick={()=>getFile(upload)}><small>{upload.name}</small></button>
                      </div>
                    })}
                  </div>
                }

                {!!context?.user?.admin &&
                  <div className="mb-3">
                    {(entry.status === 2 || entry.status === 1) &&
                        <button className="btn btn-secondary" onClick={() => undoEntry()}>Erneut Publizieren</button>
                    }

                    {entry.status === 0 &&
                      <>
                        <button className="btn btn-secondary" onClick={() => {
                          if (window.confirm("Sind Sie sicher, dass Sie diesen Eintrag archivieren möchten?")) {
                            archiveEntry();
                          }
                        }}>Archivieren</button>
                        &nbsp;
                        <button className="btn btn-danger" onClick={() => {
                          if (window.confirm("Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?")) {
                            deleteEntry();
                          }
                        }}>Löschen</button>
                      </>
                    }
                  </div>
                }
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="py-3 mb-3">
                <EntryForm tab={props.tab} onLoading={onLoading}/>
                <EntryComments />
              </div>
            </div>

          </div>
        </div>

      <Modal show={showDonwnloadsModal } onHide={handleCloseDownloadModal} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title><span className="fs-5">Heruntergeladene Dateien</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {entry.uploads.map((upload) => {
            return renderRecipientDownloads(upload)
          })}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-block mb-1 w-100">
            <img src="/assets/icons/user.svg" alt="User"/>&nbsp;
            <small>{selectedRecipient?.full_name}</small>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
