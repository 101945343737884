import React, {useCallback, useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone';

export default function DragAndDrop(props) {
  const [uploads, setUploads] = useState([]);

  const formatBytes = (bytes,decimals) => {
    if(bytes === 0) return '0 Bytes';
    let k = 1024,
        dm = decimals || 1,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const removeFile = (e, file) => {
    e.preventDefault();

    const index = uploads.indexOf(file);
    if (index > -1) {
      uploads.splice(index, 1);
      setUploads([...uploads]);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    uploads.push(acceptedFiles);
    setUploads([...uploads.flat(2)]);
  }, [])


  const files = uploads.map(file => (
    <li key={file.path}>
      <div className="d-flex justify-content-between py-1">
        <div>
          <img src="/assets/icons/file.svg" alt="" className="me-1"/>
          {file.name} <span className="text-secondary ps-3 text-right">{formatBytes(file.size)}</span>
        </div>
        <div>
          <button type="default" className="btn-reset ms-3" onClick={(e)=> removeFile(e, file)}>
            <img src='/assets/icons/trash.svg' alt=""/>
          </button>
        </div>
      </div>
    </li>
  ));

  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({maxFiles: 10, multiple: true, onDrop})

	useEffect(() => {
    props.onDrop(uploads);
	}, [uploads]);


  return (
    <div {...getRootProps({ className: "mb-3 rounded text-bg-light p-3" })}>
      <input {...getInputProps()} />
      <strong className="d-block py-3"><img src="/assets/icons/upload.svg" alt="Back to Overview"/> File-Upload</strong>
      {!!uploads?.length &&
        <>
          <hr/>
          <aside>
            <strong className="d-block mb-2">Ausgewählte Dateien</strong>
            <ul className="list-reset">{files}</ul>
          </aside>
        </>
      }
    </div>
  )
}

