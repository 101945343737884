
import React, {useEffect, useState} from 'react';

import {API_HOST, fetcher} from '../../config/config';
import Loader from "../../components/Loader/Loader";

export default function Companies(props){
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("active");

	useEffect(() => {
		(async () => {
      const companies = await fetcher(`${API_HOST}firmas?mode=${mode}`);
			setCompanies(companies);
      setLoading(false);
		})();
	}, [props, mode]);

  if (loading) {
    return <Loader/>
  }

	return (
      <div className="container-xxl bg-white text-bg-light py-4">
        <div className="row">
          <div className="col">

            <div className="row">
              <div className="col-12 col-sm-4">
                <div className="mb-4">
                  <a href={`/companies/new`} className="btn btn-primary">+ Firma hinzufügen</a>
                </div>
              </div>
              <div className="col-12 col-sm-8">
                <ul className="nav nav-underline mb-3 justify-content-end">
                  <li className="nav-item">
                    <a className={`nav-link ${(mode === 'published') ? 'active' : '' }`} aria-current="page" href="#" onClick={()=>setMode("active")}><img src="/assets/icons/check.svg" alt="Check"/> Aktuell</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${(mode === 'archived') ? 'active' : '' }`} href="#" onClick={()=>setMode("archived")}><img src="/assets/icons/inbox.svg" alt="Archiv"/> Archiv</a>
                  </li>
                </ul>
              </div>
            </div>



            <div className="table-responsive mb-3">
            <table className="table table-hover align-middle">
              <tbody>
                { companies.map((company) => {
                  return <tr key={company.id}>
                    <td><a href={`/companies/${company.id}/edit`}>{company.name}</a></td>
                    <td className="text-end">
                      <a href={`/companies/${company.id}/edit`} className="btn btn-light btn-sm">Bearbeiten</a>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
	)
}
