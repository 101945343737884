import React from 'react';
import {Routes, Route} from "react-router-dom";

import LoginLayout from "./components/Layout/LoginLayout";
import PasswordReset from "./components/Layout/PasswordReset";
import PasswordForgot from "./components/Layout/PasswordForgot";
import MainLayout from "./components/Layout/MainLayout";
import Projects from "./features/Projects/Projects";
import Project from "./features/Projects/Project";
import ProjectNew from "./features/Projects/ProjectNew";
import Tabs from "./features/Tabs/Tabs";
import Companies from "./features/Companies/Companies";
import Company from "./features/Companies/Company";
import CompanyNew from "./features/Companies/CompanyNew";
import Users from "./features/Users/Users";
import User from "./features/Users/User";
import UserNew from "./features/Users/UserNew";
import UserMe from "./features/Users/UserMe";

function App() {
  return (
		<Routes>
			<Route index element={<MainLayout tab={"projects"}><Projects/></MainLayout>}/>

			<Route path="/login" element={<LoginLayout/>}/>
			<Route path="/login/login" element={<LoginLayout/>}/>
			<Route path="/password/forgot" element={<PasswordForgot/>}/>
			<Route path="/password/reset" element={<PasswordReset/>}/>

			<Route path="/projects/new" element={<MainLayout><ProjectNew/></MainLayout>}/>
			<Route path="/projects/:project_id/edit" element={<MainLayout><Project/></MainLayout>}/>

			<Route path="/projects/:project_id/*" element={<Tabs/>}/>
			<Route path="/projects/:project_id/tab/:tab_id/*" element={<Tabs/>}/>

			<Route path="/companies" element={<MainLayout tab={"companies"}><Companies/></MainLayout>}/>
			<Route exact path="/companies/new" element={<MainLayout tab={"companies"}><CompanyNew/></MainLayout>}/>
			<Route path="/companies/:company_id/edit" element={<MainLayout tab={"companies"}><Company/></MainLayout>}/>

			<Route path="/users" element={<MainLayout tab={"users"}><Users/></MainLayout>}/>
			<Route exact path="/users/new" element={<MainLayout tab={"users"}><UserNew/></MainLayout>}/>
			<Route path="/users/:user_id/edit" element={<MainLayout tab={"users"}><User/></MainLayout>}/>
			<Route exact path="/users/me/edit" element={<MainLayout tab={"users"}><UserMe/></MainLayout>}/>

			<Route path="*" element={<MainLayout tab={"projects"}><Projects/></MainLayout>}/>
		</Routes>
  );
}

export default App;
