
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import Notification from '../../components/Notification/Notification';
import Loader from "../../components/Loader/Loader";

import {
  generatePassword,
  checkPasswordPolicy,
  validate
} from '../../services/UserService';

import {API_HOST, fetcher} from '../../config/config';

const defaultProps = {
  name: "",
  firstname: "",
  email: "",
  new_password: "",
  admin: false,
  firma_id: 0,
};

export default function UserMe(){
	const {user_id} = useParams();
  const [user, setUser] = useState(defaultProps);
	const [response, setResponse] = useState({message: '', type: ''});
  const [loading, setLoading] = useState(true);
  const [passwordMessage, setPasswordMessage] = useState({message: '', type: ''});

  const onPasswordSuggestion = () => {
    user.new_password = generatePassword();
    setUser({...user});
  }

  const onChange = (e) => {
    setUser({...user, [e.target.name]: e.target.value});
  }

  const onPasswordChange = (e) => {
    user.new_password = e.target.value;

    if (checkPasswordPolicy(user.new_password)) {
      setPasswordMessage({message: 'Passwort entspricht den Anforderungen (Mindestens: Länge 10 Zeichen, ein Grossbuchstabe, eine Zahl)', type: 'success'});
    } else {
      setPasswordMessage({message: 'Passwort entspricht nicht den Anforderungen (Mindestens: Länge 10 Zeichen, ein Grossbuchstabe, eine Zahl)', type: 'danger'});
    }
    setUser({...user});
  }

  const onSave = async () => {
		(async () => {
      try {

        const message = validate(user);
        if (message !== true) {
          setResponse({type: 'danger', message: message});
          return;
        }

        await fetcher(`${API_HOST}users/me/update`, 'PUT', user);
        setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
			} catch (error) {
				setResponse({type: 'danger', message: error?.message});
			}
		})();
  }

	useEffect(() => {
		(async () => {
      const user = await fetcher(`${API_HOST}users/me/show`);
			setUser(user);
      setLoading(false);
		})();
	}, [user_id]);

  if (loading) {
    return <Loader/>
  }

	return (
		<>
			<Notification message={response}/>

      <div className="container-xxl bg-white text-bg-light py-4 mb-4">
        <div className="row">
          <div className="col">

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="firstname" className="form-label">Vorname</label>
                    <input type="text" className="form-control" id="firstname" name="firstname" value={user?.firstname}  onChange={(e)=> onChange(e) }/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={user?.name}  onChange={(e)=> onChange(e) }/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">E-Mail</label>
                    <input type="email" className="form-control" id="eimal" name="email" value={user?.email}  onChange={(e)=> onChange(e) }/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="client" className="form-label">Telefon</label>
                    <input type="text" className="form-control" id="name" name="phone" value={user?.phone}  onChange={(e)=> onChange(e) }/>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Passwort ändern</label>
                    <input type="text" className="form-control" id="password" name="new_password" value={user.new_password}  onChange={(e)=> onPasswordChange(e) }/>
                    <button className='btn btn-info' onClick={onPasswordSuggestion}>Passwort vorschlagen</button>

                    {user?.new_password?.length > 0 &&
                      <Notification message={passwordMessage}/>
                    }

                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="client" className="form-label">Firma</label>
                    <p>{user?.firma}</p>
                  </div>

                  <div className="table-responsive mb-3">
                    <table className="table table-hover align-middle">
                      <thead>
                        <tr>
                          <th>Ihre Projekte</th>
                        </tr>
                      </thead>
                      <tbody>
                        { user?.projects.map((project) => {
                          return <tr key={project.id}>
                            <td><a href={`/projects/${project.id}`}>{project.name}</a></td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

                <div className="py-3">
                  <button type="submit" name="submit" className="btn btn-primary" onClick={onSave}>Speichern</button>
                  <a className="px-4" href="/users">Abbrechen</a>
                </div>
            </div>
          </div>
        </div>
    </>
  )
}
