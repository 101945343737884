import React, {useEffect, useState} from 'react';

export default function EntryNotifications(props){
  const [sortedUsers, setSortedUsers] = useState({});
  const [selectedUser, setSelectedUser] = useState([]);
  const [ignoreWarning, setIgnoreWarning] = useState(false);
  const [status, setStatus] = useState(false);

  const handleIgnoreWarning = () => {
      setIgnoreWarning(true);
  }

  const toggleCompanyUser = (firma) => {
    const companyUsers = sortedUsers[firma];

    companyUsers.forEach((user) => {
      if (selectedUser.includes(user.id)) {
        selectedUser.splice(selectedUser.indexOf(user.id), 1);
      } else {
        selectedUser.push(user.id);
      }
    });

    setSelectedUser([...selectedUser]);
  }

  const toggleUser = (user) => {
    if (selectedUser.includes(user.id)) {
      selectedUser.splice(selectedUser.indexOf(user.id), 1);
    } else {
      selectedUser.push(user.id);
    }

    setSelectedUser([...selectedUser]);
  }

  const orderUsersByFirma = () => {
    const sortedUsers = {}
    props.tab.users.forEach((user) => {
      if (sortedUsers.hasOwnProperty(user.firma)) {
        sortedUsers[user.firma].push(user);
      } else {
        sortedUsers[user.firma] = [];
        sortedUsers[user.firma].push(user);
      }
    });
    setSortedUsers(sortedUsers);
  }

	useEffect(() => {
    orderUsersByFirma();
	}, []);

	useEffect(() => {
    props.onUserSelection(selectedUser);
	}, [selectedUser]);

	useEffect(() => {
    if (ignoreWarning || selectedUser.length > 0) {
      setStatus(true);
      props.onStatusChange(false);
    } else {
      setStatus(false);
      props.onStatusChange(true);
    }

	}, [ignoreWarning, selectedUser]);

	return (
		<>
      <p><strong>Benachrichtigung</strong></p>
        {Object.entries(sortedUsers).map(([firma, users], index) => {
          return <div className="mb-3 rounded text-bg-light p-3" key={firma}>
            <div className="form-check" >
              <input type="checkbox" className="form-check-input" id={`notify-group-${index}`} onChange={()=>toggleCompanyUser(firma)}/>
              <label className="form-check-label" htmlFor={`notify-group-${index}`}><strong>{firma}</strong></label>
            </div>
              {users.map((user) => {
                return <div className="form-check d-inline-block me-3" key={user.id}>
                  <input type="checkbox" className="form-check-input" id={`notify-group-${user.id}`} checked={selectedUser.includes(user.id)} onChange={()=> toggleUser(user)}/>
                  <label className="form-check-label" htmlFor={`notify-group-${user.id}`}><small>{user.full_name}</small></label>
                </div>
              })}
          </div>
        })}

      {(!status) &&
        <div className="alert alert-warning" role="alert">
	        <div className="d-flex justify-content-between align-items-center">
            <span>Es wurde keine Person ausgewählt, die über die Aktion benachrichtigt werden soll.</span> <span><button className="btn-reset text-decoration-underline" onClick={handleIgnoreWarning}>Ignorieren</button></span>
          </div>
        </div>
      }
    </>
  )
}
