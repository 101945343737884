import React from 'react';

export default function PublicHeader() {
  return (
    <header className="mb-4">
      <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
        <div className="container-xxl">

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">SYNFONI</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="bg-brand text-white py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-between">
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
