
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {API_HOST, BIN_JWT_TOKEN } from '../../config/config';

import PublicHeader from "../Header/PublicHeader";
import Footer from "../Footer/Footer";
import Notification from '../Notification/Notification';

async function sendForgotRequest(email) {
	try {
		const response = await fetch(`${API_HOST}password/forgot`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({email}),
		});

		if (response.status === 401 || response.status === 500 ) {
		  const error = await response.json();
		  throw new Error( error.exception);
    }

		const token = await response.json();
		localStorage.setItem(BIN_JWT_TOKEN, token.token);
		return token;
	} catch (error) {
		console.error('Error:', error);
	}
}

function LoginLayout() {
	const [email, setEmail] = useState([]);
	const [hasError, setHasError] = useState(false);
	const [response, setResponse] = useState({message: '', type: ''});

	const updateEmail = (event) => {
		setEmail(event.target.value);
	}

	const onSubmit = () => {
		(async () => {
      try {
        if (email.length === 0) {
          setHasError(true);
          return;
        }

        if (await sendForgotRequest(email)) {
          setEmail("");
          setResponse({type: 'warning', message: "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet. Bitte prüfen Sie Ihr Spam-Fach."});
        } else {
          setHasError(true);
        }
      } catch (error) {
        console.error('Error:', error);
        setHasError(true);
      }
		})();
	}

	return (
		<>
      <PublicHeader/>
      <div className="container-xxl bg-white text-bg-light mt-5 py-4">
        <div className="row">
          <div className="col">
            <Notification message={response}/>
            <h2 className="mb-4">Passwort zurücksetzen</h2>
          </div>
        </div>

        <div className="row">
          <div className="col">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">E-Mail</label>
                <input type="email" className={`form-control ${hasError ?  `is-invalid` : '' }`} id="email" value={email} onChange={updateEmail}/>
              </div>
              <div className="py-3">
                <button type="button" className="btn btn-primary" onClick={onSubmit} >Passwort zurücksetzen</button>
                <a className="px-4" href="/">Abbrechen</a>
              </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default LoginLayout;
