import React, {useEffect, useState, useContext} from 'react';
import { Modal, Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import {API_HOST, fetcher} from '../../config/config';
import Notification from '../../components/Notification/Notification';
import {AppContext} from "../../config/context";

const STANDARD_PLUGIN = 2;

const defaultTab = {
  name: '',
  plugin_id: STANDARD_PLUGIN,
  position: 0,
  project_id: 0,
}
const defaultProject = {
  name: '',
  tabs: []
}

export default function Project(){
	const { project_id } = useParams();
  const [project, setProject] = useState(defaultProject);
  const [firmas, setFirmas] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [selectedFirma, setSelectedFirma] = useState(0);
  const [selectedUser, setSelectedUser] = useState(0);
  const [plugins, setPlugins] = useState([]);
	const [response, setResponse] = useState({message: '', type: ''});
	const [permissionResponse, setPermissionResponse] = useState({message: '', type: ''});
  const [newTab, setNewTab] = useState(defaultTab);
  const [firmaUsers, setFirmaUsers] = useState([]);
  const [reloadPermissions, setReloadPermissions] = useState(false);
  const [showUserSelector, setShowUserSelector] = useState(false);
  const [reload, setReload] = useState(false);

	const context = useContext(AppContext);
	const navigate = useNavigate();

  const handleCloseUserSelector = () => setShowUserSelector(false);
  const handleShowUserSelector = () => setShowUserSelector(true);

  const firmasOptions = () => {
    const options = [];
    options.push(<option value={0} key={0}>– Auswählen –</option>);

    firmas.forEach((firma) => {
        options.push(<option value={firma.id} key={firma.id}>{firma?.name}</option>);
    })
    return options;
  }

  const usersOptions = () => {
    const options = [];
    options.push(<option value={0} key={0}>– Auswählen –</option>);

    firmaUsers.forEach((user) => {
      options.push(<option value={user.id} key={user.id}>{user?.full_name}</option>);
    })
    return options;
  }

  const pluginsOptions = () => {
    const options = [];
    plugins.forEach((plugin) => {
        options.push(<option value={plugin.id} key={plugin.id}>{plugin?.name}</option>);
    })
    return options;
  }

  const onArchive = () => {
    project.archived = !project.archived;
    setProject({...project});
  }

  const onNameChange = (e) => {
    project.name = e.target.value;
    setProject({...project});
  }

  const onCustomerChange = (e) => {
    project.customer_id = parseInt(e.target.value);
    setProject({...project});
  }

  const onFirmaChange = (e) => {
    setSelectedFirma(parseInt(e.target.value));
  }

  const onUserChange = (e) => {
    setSelectedUser(parseInt(e.target.value));
  }

  const onTabNameChange = (e, index) => {
    project.tabs[index].name = e.target.value;
    setProject({...project});
  }

  const onTabPluginChange = (e, index) => {
    project.tabs[index].plugin_id = parseInt(e.target.value);
    setProject({...project});
  }

  const onTabDelete = (tab) => {
    project.tabs = project.tabs.filter((t) => t.id !== tab.id);
    setProject({...project});
  }

  const onNewTabNameChange = (e) => {
    newTab.name = e.target.value;
    setNewTab({...newTab});
  }

  const onNewTabPluginChange = (e) => {
    newTab.plugin_id = parseInt(e.target.value);
    setNewTab({...newTab});
  }

  const onTabAdd = () => {
    project.tabs.push(structuredClone(newTab));
    setProject({...project});
    setNewTab({ name: '', plugin_id: 0, position: 0, project_id: 0 })
  }

  const checkNewTabWasEdited = () => {
    if (newTab.name.length > 0) {
      onTabAdd();
    }
  }

  const groupByFirma = (permissions) => {
    const grouped = {};
    permissions.forEach((permission) => {
      if (!grouped[permission.firma]) {
        grouped[permission.firma] = [];
      }

      if (!grouped[permission.firma][permission.user]) {
        grouped[permission.firma][permission.user] = [];
      }
      grouped[permission.firma][permission.user].push(permission);
    })
    return grouped;
  }

  const togglePermission = (tab) => {
		(async () => {
			try {
				await fetcher(`${API_HOST}permissions/${tab.id}`, 'PUT');
				setPermissionResponse({type: 'success', message: 'Erfolgreich aktualisiert'});
        setReloadPermissions(true);
			} catch (error) {
				setPermissionResponse({type: 'danger', message: error?.message});
			}
		})();
  }

	const onUpdate = () => {
		(async () => {
			try {

        // add new tab if it was edited and user forgot to click add
        checkNewTabWasEdited();

				await fetcher(`${API_HOST}projects/${project_id}`, 'PUT', project);
				setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
        setReload(true);
			} catch (error) {
				setResponse({type: 'danger', message: error?.message});
			}
		})();
	}

  const createUserPermission = async () => {
    try {
      await fetcher(`${API_HOST}permissions`, 'POST', {project_id: project_id, user_id: selectedUser});
      setResponse({type: 'success', message: 'Erfolgreich gespeichert'});
    } catch (error) {
      setResponse({type: 'danger', message: error?.message});
    }
    setReloadPermissions(true);
    setShowUserSelector(false);
  }

  const removeUser = async (user_id) => {
    try {
      await fetcher(`${API_HOST}permissions`, 'DELETE', {project_id: project_id, user_id: user_id});
      setResponse({type: 'success', message: 'Erfolgreich gelöscht'});
    } catch (error) {
      setResponse({type: 'danger', message: error?.message});
    }
    setReloadPermissions(true);
  }

  const onProjectDelete = async () => {
    try {
      await fetcher(`${API_HOST}projects/${project_id}`, 'DELETE');
      navigate(`/`);
    } catch (error) {
      setResponse({type: 'danger', message: error?.message});
    }
    setReloadPermissions(true);
  }

  const renderUserTabPermission = (tabs, id ) => {
    if (tabs.length && tabs[0].access) {
      return <td key={Math.random()}>
            	<button className="btn btn-xs btn-icon-only btn-success" onClick={()=> togglePermission(tabs[0])}><img src="/assets/icons/check--white.svg" alt="all permissions" width="14" /></button>
        </td>
    } else {
      return <td key={Math.random()}>
            	<button className="btn btn-xs btn-icon-only btn-danger" onClick={()=> togglePermission(tabs[0])}><img src="/assets/icons/x--white.svg" alt="no permissions" width="14" /></button>
        </td>
    }
  }

	useEffect(() => {
		(async () => {
      const project = await fetcher(`${API_HOST}projects/${project_id}?without_users`);
			setProject(project);
      const firmas = await fetcher(`${API_HOST}firmas`);
			setFirmas(firmas);
      const plugins = await fetcher(`${API_HOST}plugins`)
			setPlugins(plugins);
      setReload(false);
		})();
	}, [project_id, reload === true]);

	useEffect(() => {
		(async () => {

      if (selectedFirma === 0) {
        return;
      }

      const users = await fetcher(`${API_HOST}firmas/${selectedFirma}/users`);
      if (users?.length) {
        setFirmaUsers(users);
      }

		})();
	}, [selectedFirma]);

	useEffect(() => {
		(async () => {
      const permissions = await fetcher(`${API_HOST}projects/${project_id}/permissions`);
			setPermissions(groupByFirma(permissions));
			setReloadPermissions(false);
		})();
	}, [project_id, reloadPermissions === true, reload === true]);

	return (
		<>
			<Notification message={response}/>

      <div className="container-xxl pt-2">
        <div className="row">
          <div className="col">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Projekt bearbeiten</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-xxl bg-white text-bg-light py-4 mb-4">
        <div className="row">
          <div className="col">

            <ul className="nav nav-underline mb-3 justify-content-end">
              <li className="nav-item">
                <a className="nav-link" href="#tabs"><img src="/assets/icons/folder.svg" alt="Projects"/> Projektbereiche</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#permissions"><img src="/assets/icons/lock.svg" alt="Permissions"/> Berechtigungen</a>
              </li>
            </ul>

              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Projektname</label>
                    <input type="text" className="form-control" id="name" name="name" value={project.name} onChange={(e)=> onNameChange(e) }/>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="client" className="form-label">Kunde</label>
                    <select className="form-select" id="client" value={project?.customer_id} onChange={(e)=> onCustomerChange(e)}>
                      { firmasOptions(project?.customer_id) }
                    </select>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <div className="mb-3">
                    <div className="form-check d-inline-block me-3">
                      <input type="checkbox" className="form-check-input" id="archived" checked={project.archived} onChange={()=> onArchive() }/>
                      <label className="form-check-label" htmlFor="archived">Projekt archivieren</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                  {context.user?.superadmin &&
                      <button type="submit" name="submit" className="btn btn-danger" onClick={()=> {
                              if (window.confirm("Soll dieses Projekt wirklich gelöscht werden? Dieser Schritt ist unwiderruflich!")) {
                                onProjectDelete();
                              }
                            }}
                      >Projekt löschen</button>
                  }
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <h2 className="mb-3" id="tabs">Projektbereiche</h2>
                  { project?.tabs.map((tab, index) => {
                    return <div className="rounded border text-bg-light px-3 py-3 mb-2" key={index}>
                      <div className="row">
                        <div className="col-12 col-md-7">
                          <div className="d-flex align-items-center mb-1">
                            <span ><img src="/assets/icons/menu.svg" alt="Move"/></span>
                            <input type="name" className="form-control ms-3" id="name" name="name" value={tab.name} onChange={(e)=> onTabNameChange(e, index) }/>
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="d-flex align-items-center mb-1">
                            <select className="form-select" id="type" value={tab?.plugin_id} onChange={(e)=> onTabPluginChange(e, index)}>
                              { pluginsOptions(tab?.plugin_id) }
                            </select>
                            <button type="default" className="btn-reset ms-3" onClick={()=> {
                              if (window.confirm("Soll dieser Tab wirklich gelöscht werden?")) {
                                onTabDelete(tab)
                              }
                            }} >
                              <img src='/assets/icons/trash.svg' alt="Delete"/>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }) }

                  <div className="rounded border text-bg-light px-3 py-3 mb-2" >
                    <div className="row">
                      <div className="col-12 col-md-7">
                        <div className="d-flex align-items-center mb-1">
                          <span ><img src="/assets/icons/menu.svg" alt="Move"/></span>
                          <input type="name" className="form-control ms-3" id="name" name="name" value={newTab.name} onChange={(e)=> onNewTabNameChange(e) }/>
                        </div>
                      </div>
                      <div className="col-12 col-md-5">
                        <div className="d-flex align-items-center mb-1">
                          <select className="form-select" id="type" value={newTab?.plugin_id} onChange={(e)=> onNewTabPluginChange(e)}>
                            { pluginsOptions() }
                          </select>
                          <button type="default" className="btn-reset ms-3" onClick={()=> {
                            onTabAdd()
                        }} >
                          <img src='/assets/icons/plus.svg' alt="Add"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-3">
                  <button type="submit" name="submit" className="btn btn-primary" onClick={onUpdate}>Speichern</button>
                  <a className="px-4" href="/">Abbrechen</a>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <Notification message={permissionResponse}/>

    <div className="container-xxl bg-white text-bg-light py-4 mb-4">
      <div className="row">
        <div className="col">
          <h2 className="mb-3" id="permissions">Berechtigungen</h2>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
              <tr>
                <th></th>
                { project?.tabs.map((tab, index) => {
                  return <th key={index}>{tab.name}</th>
                } ) }
                </tr>
             </thead>

              { Object.entries(permissions).map(([firma, users]) => {
                return <tbody key={Math.random()}>
                  <tr className="table-secondary">
                  <th key={firma}>{firma}</th>
                    { project?.tabs.map((tab) => {
                      return <th key={Math.random()}></th>
                    })}
                  </tr>
                  { Object.entries(users).map(([user, perms]) => {
                    return <tr key={Math.random()}>
                      <td key={Math.random()}>
                        <button className="btn btn-xs btn-icon-only btn-danger me-1" onClick={ () => {
                          if (window.confirm("Sind Sie sicher, dass Sie diesen Nutzer aus dem Projekt entfernen möchten?")) {
                            removeUser(perms[0].user_id)
                          }
                        }}>
                            <img src="/assets/icons/x--white.svg" width="14" alt="Benutzer entfernen"/>
                        </button>&nbsp;
                        {user}
                      </td>
                      { project?.tabs.map((tab) => {
                        return renderUserTabPermission(perms.filter((perm) => perm.tab_id === tab.id), user.id);
                      })}
                    </tr>
                  })}
                </tbody>
               })}
            </table>
          </div>

         <div className="py-3">
					<button name="submit" className="btn btn-primary" onClick={handleShowUserSelector}>Benutzer hinzufügen</button>
				</div>

        </div>
      </div>

      <Modal show={showUserSelector} onHide={handleCloseUserSelector} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Benutzer hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="mb-3">
          <label htmlFor="client" className="form-label">Kunde</label>
          <select className="form-select" id="client" value={selectedFirma} onChange={(e)=> onFirmaChange(e)}>
            { firmasOptions() }
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="user" className="form-label">Benutzer</label>
          <select className="form-select" id="user" value={selectedUser} onChange={(e)=> onUserChange(e)}>
            { usersOptions() }
          </select>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserSelector}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={createUserPermission}>Speichern</Button>
        </Modal.Footer>
    </Modal>
    </div>
   </>
  )
}
