export default function Loader() {
    return (
      <div className="position-absolute top-0 end-0 bottom-0 start-0 bg-light d-flex align-items-center justify-content-center opacity-75">
        <div className="d-flex align-items-center">
          <span className="spinner-border" role="status"></span>
          <span className="ps-2">Daten werden geladen …</span>
        </div>
      </div>
    )
}
