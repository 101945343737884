
export const generatePassword = () => {
    const length = 10;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#%*$";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  }

  export const checkPasswordPolicy = (password)=> {
    if (!password) {
      return false
    }

    if (password?.length < 10) {
      return false
    }

    if (!password.match(/[A-Z]+/)) {
      return false
    }

    if (!password.match(/[0-9]+/)) {
      return false
    }

    return true
  }

  export const validate = (user, withPassword = false) => {
    if (!user.name || user.name?.length === 0) {
      return "Bitte geben Sie den Namen des Benutzers ein";
    }

    if (!user.firstname || user.firstname?.length === 0) {
      return "Bitte geben Sie den Vornamen des Benutzers ein";
    }

    if (withPassword) {
      if (!user.new_password || user.new_password?.length === 0) {
        return "Bitte geben Sie das Passwort des Benutzers ein";
      }

      if (!checkPasswordPolicy(user?.new_password)) {
        return "Das eingegene Passwort entspricht nicht den Anforderungen (Mindestens: Länge 10 Zeichen, ein Grossbuchstabe, eine Zahl)";
      }
    }

    if (!user.email || user.email.length === 0) {
      return "Bitte geben Sie die E-Mail des Benutzers ein";
    }

    if (parseInt(user.firma_id) === 0) {
      return "Bitte wählen Sie die Firma des Benutzers aus";
    }

    return true;
  }

